


















































import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'Question519',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        multipleChoiceAnswer: null,
        language: 'fr',
      },
      options: [
        {text: '1', value: '1'},
        {text: '2', value: '2'},
        {text: '3', value: '3'},
        {text: '4', value: '4'},
      ],
    };
  },
  computed: {
    imageName() {
      return '/img/assignments/q519.png';
    },
  },
};
